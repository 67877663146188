import React from 'react';
import './All.css';

function Crimes() {


  return (
    <section>
      <div id="all-items">
        <h1>LA & NYC CRIMES</h1>
        <p><span id="objective">OBJECTIVE</span><br></br>
        Developed a full-stack website to retrieve crime information for Los 
        Angeles (LA) and New York City (NYC) by interacting with daatabase.
        Implemented the backend using Node.js and Express, creating robust 
        REST APIs to interact with client-side for efficient data retrieval.
        Designed the frontend using React, offering a chart and graph 
        component to visually compare crime data for LA and NYC.
        Successfully optimized performance in both client and server side 
        resulting in a significant improvement in overall responsiveness.
        Visit it <a href="http://35.182.147.211/">here</a>.
        </p>
        <div id="down">
          <div id="all-left">
          <h3>Languages & Frameworks</h3>
          <ul>
              <li>React.js-frontend </li>
              <li>Node.js-backend</li>
              <li>MongoDb-database</li>
              <li>GitLab CI - CI/CD</li>
              <li>HTML</li>
              <li>CSS</li>
            </ul>
          </div>
          <div id="all-right">
          <h3>Features</h3>
          <ul>
              <li></li>
              <li>Optimized Performance</li>
              <li>Graph and bar charts</li>
              <li>Interact with 14K rows of real data</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Crimes;