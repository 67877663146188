import React from 'react';
import './All.css';

import game from "./../Game.PNG";
import game1 from "./../game1.PNG";
import game2 from "./../game2.PNG";

function Game() {


  return (
    <section>
      <div id="all-items">
        <h1>MAZE GAME</h1>
        <p>
        Developed a 2D Maze game in C# and .NET with MonoGame. Incorporated player interactions, map generation, and diverse 
        algorithms, and offering users menu-driven map creation methods 
        including recursion or importing directions from a text file.
        Employed Benchmark testing to assess the performance of each 
        algorithm, optimizing the game for efficiency.
        </p>
        <table>
            <tr>
              <td><img className="all-images" src={game} alt="game"></img></td>
              <td><img className="all-images" src={game1} alt="game1"></img></td>
              <td><img className="all-images" src={game2} alt="game2"></img></td>
            </tr>
          </table>
        <div id="down">
          <div id="all-left">
          <h3>Languages & Frameworks</h3>
          <ul>
              <li>C#</li>
              <li>.NET</li>
              <li>MonoGame</li>
              <li>Gitlab CI</li>
            </ul>
          </div>
          <div id="all-right">
          <h3>Features</h3>
          <ul>
              <li>User can upload their own path</li>
              <li>User can select width and height of the maze</li>
              <li>User can select easy or hard mode</li>
              <li>Improved performance</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Game;
